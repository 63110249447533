import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  Label,
} from "reactstrap"
import ReactApexChart from "react-apexcharts"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Doughnut } from "react-chartjs-2"
import { Bar } from "react-chartjs-2"

function Dashbaord1Chart() {
  const [master, setmaster] = useState({ date: "2023-10-13" })

  // staff attendance data

  const series = [30, 55, 41]
  const options = {
    labels: ["Absent", "Present", "Half Day"],
    colors: ["#ef0c2c", "#3251e7", "#787b7e"],
    legend: {
      show: !0,
      position: "right",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: !1,
      fontSize: "14px",
      offsetX: 0,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: !1,
          },
        },
      },
    ],
  }

  //donut chart 1
  const data = {
    labels: ["Active ", "Inactive"],
    datasets: [
      {
        data: [300, 210],
        backgroundColor: ["#556ee6", "#ebeff2"],
        hoverBackgroundColor: ["#556ee6", "#ebeff2"],
        hoverBorderColor: "#fff",
      },
    ],
  }
  //daunout2
  const data2 = {
    labels: ["Visit", "No Vist"],
    datasets: [
      {
        data: [250, 50],
        backgroundColor: ["#556ee6", "#ebeff2"],
        hoverBackgroundColor: ["#556ee6", "#ebeff2"],
        hoverBorderColor: "#fff",
      },
    ],
  }

  const data3 = {
    labels: ["Paid", "Unpaid"],
    datasets: [
      {
        data: [225, 25],
        backgroundColor: ["#556ee6", "#ebeff2"],
        hoverBackgroundColor: ["#556ee6", "#ebeff2"],
        hoverBorderColor: "#fff",
      },
    ],
  }

  const data4 = {
    labels: ["Unpaid", "Paid"],
    datasets: [
      {
        data: [25, 225],
        backgroundColor: ["#556ee6", "#ebeff2"],
        hoverBackgroundColor: ["#556ee6", "#ebeff2"],
        hoverBorderColor: "#fff",
      },
    ],
  }

  // bar chart data

  const data5 = {
    labels: ["Daily", "Weekly", "15days", "Monthly"],
    datasets: [
      {
        label: "Visit ",
        backgroundColor: "rgba(52, 195, 143, 0.8)",
        borderColor: "rgba(52, 195, 143, 0.8)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(52, 195, 143, 0.9)",
        hoverBorderColor: "rgba(52, 195, 143, 0.9)",
        data: [15, 20, 25, 40],
      },
    ],
  }

  const option5 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.3,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true, // Start the y-axis at 0
            stepSize: 10, // Set the interval between y-axis ticks
          },
        },
      ],
    },
  }

  // up paid splitup chart

  const seriesunpaid = [30, 55, 41]
  const optionsunpaid = {
    labels: ["Door Closed", "No waste", "Pending"],
    colors: ["#EE4B2B", "rgba(52, 195, 143, 0.8)", "#00FFFF"],
    legend: {
      show: !0,
      position: "right",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: !1,
      fontSize: "14px",
      offsetX: 0,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: !1,
          },
        },
      },
    ],
  }

  // line target charts

  const seriesTarget = [
    { name: "target", data: [26, 26, 26, 26, 26, 26, 26] },
    { name: "Achieved", data: [14, 11, 16, 12, 17, 13, 12] },
  ]
  const optionsTarget = {
    chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
    colors: ["#5b73e8", "#f1b44c"],
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      width: [2, 2],
      curve: "straight",
    },
    title: {
      text: "Target and Achieved",
      align: "left",
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.1,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 5,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      title: {
        text: "Month",
      },
    },
    yaxis: {
      title: {
        text: "Target",
      },
      min: 5,
      max: 40,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Row style={{ rowGap: "20px" }}>
            <Col md="6">
              <Card style={{ height: "100%", marginBottom: "0px" }}>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <h6>Staff Attendance</h6>
                      <h6>Total Staff: 126</h6>
                    </Col>
                    <Col
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      md="6"
                    >
                      <AvForm>
                        <AvField name="date" type="date" value={master?.date} />
                      </AvForm>
                    </Col>
                  </Row>

                  <ReactApexChart
                    options={options}
                    series={series}
                    type="pie"
                    height="200px"
                    className="apex-charts"
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Row>
                <Col style={{ marginBottom: "10px" }} md="6">
                  <Card style={{ marginBottom: "0px", height: "100%" }}>
                    <CardBody>
                      <h6>
                        Customer:{" "}
                        <span style={{ fontSize: "13px" }}>
                          <b>510</b>
                        </span>
                      </h6>
                      <Doughnut width={474} height={180} data={data} />
                    </CardBody>
                  </Card>
                </Col>
                <Col style={{ marginBottom: "10px" }} md="6">
                  <Card style={{ marginBottom: "0px", height: "100%" }}>
                    <CardBody>
                      <h6>
                        Waste Collection:{" "}
                        <span style={{ fontSize: "13px" }}>
                          <b>300</b>
                        </span>
                      </h6>

                      <Doughnut width={474} height={180} data={data2} />
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card style={{ marginBottom: "0px", height: "100%" }}>
                    <CardBody>
                      <h6>
                        Paid:{" "}
                        <span style={{ fontSize: "13px" }}>
                          <b>250</b>
                        </span>
                      </h6>
                      <Doughnut width={474} height={180} data={data3} />
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card style={{ marginBottom: "0px", height: "100%" }}>
                    <CardBody>
                      <h6>
                        Unpaid:{" "}
                        <span style={{ fontSize: "13px" }}>
                          <b>250</b>
                        </span>
                      </h6>
                      <Doughnut width={474} height={180} data={data4} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <Card>
                <CardBody>
                  <h6>Toatal Visit Summary</h6>

                  <Bar
                    width={474}
                    height={200}
                    data={data5}
                    options={option5}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <h5>UnPaid Splitup</h5>
                    </Col>
                    <Col
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      md="6"
                    >
                      <AvForm>
                        <AvField name="date" type="date" value={master?.date} />
                      </AvForm>
                    </Col>
                  </Row>

                  <ReactApexChart
                    options={optionsunpaid}
                    series={seriesunpaid}
                    type="pie"
                    height="200"
                    className="apex-charts"
                  />
                </CardBody>
              </Card>
            </Col>

            {/* <Col md="6">
              <Card>
                <CardBody>
                  <ReactApexChart
                    options={optionsTarget}
                    series={seriesTarget}
                    type="line"
                    height="240"
                    className="apex-charts"
                  />
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </div>
      </div>
    </>
  )
}

export default Dashbaord1Chart
