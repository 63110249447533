import React from "react"
import ReactApexChart from "react-apexcharts"

const dountchart = () => {
  const series = [44, 55, 41, 17]
  const options = {
    labels: ["Paper/cardboard", "Plastic glass", "Plastic covers", "Glass"],
    colors: ["#003f5c", "#58508d", "#bc5090", "#ff6361"],
    legend: {
      show: !0,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: !1,
      fontSize: "14px",
      offsetX: 0,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: !1,
          },
        },
      },
    ],
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height="320"
      className="apex-charts"
    />
  )
}

export default dountchart
