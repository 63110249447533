import React from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
// import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
//Import Components
import MiniWidget from "./mini-widget"
import SalesAnalyticsChart from "./salesanalytics-chart"
// import TopProduct from "./topselling-product"
// import TopUser from "./topuser"
import RecentActivity from "./recent-activity"
// import SocialSource from "./socialsource"
import LatestTransaction from "./latest-transaction"
import { APP_NAME } from "../../../config"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import BarChart from "./barchart"
import PieChart from "./PieChart"
import PieChartTwo from "./PieChartTwo"
import PolarChart from "./PolarChart"

//Import Image
// import setupanalytics from "../../assets/images/setup-analytics-amico.svg"

const series1 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
  },
]

const options1 = {
  fill: {
    colors: ["#5b73e8"],
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: !1,
    },
    x: {
      show: !1,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ""
        },
      },
    },
    marker: {
      show: !1,
    },
  },
}

const series2 = [70]

const options2 = {
  fill: {
    colors: ["#34c38f"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
}

const series3 = [55]

const options3 = {
  fill: {
    colors: ["#5b73e8"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
}

const series4 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
  },
]

const options4 = {
  fill: {
    colors: ["#f1b44c"],
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: !1,
    },
    x: {
      show: !1,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return ""
        },
      },
    },
    marker: {
      show: !1,
    },
  },
}

const Dashboard = props => {
  document.title = `Dashboard | ${APP_NAME}`
  const reportsOne = [
    {
      id: 1,
      icon: "mdi mdi-arrow-up-bold",
      title: "Active Projects",
      value: 70,
      prefix: "",
      suffix: "",
      badgeValue: 140,
      decimal: 0,
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      color: "success",
      desc: "Total Projects",
      series: series1,
      options: options1,
      width: 6,
    },
    {
      id: 2,
      icon: "mdi mdi-arrow-up-bold",
      title: "Unfinished Projects",
      value: 50,
      prefix: "",
      suffix: "",
      decimal: 0,
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      color: "success",
      series: series1,
      options: options1,
      width: 6,
    },

    {
      id: 3,
      icon: "mdi mdi-arrow-down-bold",
      title: "Total Budgets",
      value: 1579999,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      badgeValue: "998549",
      color: "danger",
      desc: "Used budgets",
      series: series3,
      options: options3,
      width: 6,
    },
    {
      id: 4,
      icon: "mdi mdi-arrow-up-bold",
      title: "Growth",
      value: 12.58,
      decimal: 2,
      prefix: "+",
      suffix: "%",
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      badgeValue: "10.51%",
      color: "success",
      desc: "since last week",
      series: series4,
      options: options4,
      width: 6,
    },
  ]

  const reportsTwo = [
    {
      id: 1,
      icon: "mdi mdi-arrow-up-bold",
      title: "Cooperation",
      value: 1,
      prefix: "",
      suffix: "",
      decimal: 0,
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      // badgeValue: "998549",
      color: "success",
      // desc: "Take A Break",
      series: series1,
      options: options1,
      width: 4,
    },
    {
      id: 2,
      icon: "mdi mdi-arrow-up-bold",
      title: "Municipality",
      value: 9,
      prefix: "",
      suffix: "",
      decimal: 0,
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      color: "success",
      series: series1,
      options: options1,
      width: 4,
    },

    // {
    //   id: 3,
    //   icon: "mdi mdi-arrow-down-bold",
    //   title: "Block",
    //   value: 11,
    //   decimal: 0,
    //   prefix: "",
    //   suffix: "",
    //   charttype: "radialBar",
    //   chartheight: 45,
    //   chartwidth: 45,
    //   // badgeValue: "998549",
    //   color: "danger",
    //   // desc: "Used budgets",
    //   series: series3,
    //   options: options3,
    //   width: 4,
    // },
    {
      id: 4,
      icon: "mdi mdi-arrow-down-bold",
      title: "Grama Panchayat",
      value: 71,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      // badgeValue: "998549",
      color: "danger",
      // desc: "Used budgets",
      series: series3,
      options: options3,
      width: 4,
    },
  ]
  const reportsThree = [
    {
      id: 1,
      icon: "mdi mdi-arrow-up-bold",
      title: "Active TAB",
      value: 3,
      prefix: "",
      suffix: "",
      decimal: 0,
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      badgeValue: "8",
      color: "success",
      desc: "Total TAB",
      series: series1,
      options: options1,
      width: 4,
    },
    {
      id: 2,
      icon: "mdi mdi-arrow-up-bold",
      title: "Active TAB",
      value: 9,
      prefix: "",
      suffix: "",
      decimal: 0,
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      color: "success",
      badgeValue: "45",
      desc: "Total TAB",
      series: series1,
      options: options1,
      width: 4,
    },

    // {
    //   id: 3,
    //   icon: "mdi mdi-arrow-down-bold",
    //   title: "Block",
    //   value: 11,
    //   decimal: 0,
    //   prefix: "",
    //   suffix: "",
    //   charttype: "radialBar",
    //   chartheight: 45,
    //   chartwidth: 45,
    //   // badgeValue: "998549",
    //   color: "danger",
    //   // desc: "Used budgets",
    //   series: series3,
    //   options: options3,
    //   width: 4,
    // },
    {
      id: 4,
      icon: "mdi mdi-arrow-down-bold",
      title: "Active TAB",
      value: 10,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      badgeValue: "142",
      color: "success",
      desc: "Total TAB",
      series: series3,
      options: options3,
      width: 4,
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Home"
            breadcrumbItem={`${
              props.name ? props.name + " Dashboard" : "Take A Break"
            }`}
          />
          <Row id="dashboard-head ">
            <AvForm>
              <Col>
                <Row className="dashboard-filter">
                  <Col md="2 mb-2">
                    <Select
                      isClearable
                      name="batch"
                      width={50}
                      placeholder="Districts"
                      options={[
                        {
                          label: "Thiruvananthapuram",
                          value: "Thiruvananthapuram",
                        },
                        { label: "Kollam", value: "Kollam" },
                        { label: "Pathanamthitta", value: "Pathanamthitta" },
                        { label: "Alappuzha", value: "Alappuzha" },
                        { label: "Kottayam", value: "Kottayam" },
                        { label: "Idukki", value: "Idukki" },
                        { label: "Ernakulam", value: "Ernakulam" },
                        { label: "Thrissur", value: "Thrissur" },
                        { label: "Palakkad", value: "Palakkad" },
                        { label: "Malappuram", value: "Malappuram" },
                        { label: "Wayanad", value: "Wayanad" },
                        { label: "Kozhikode", value: "Kozhikode" },
                        { label: "Kannur", value: "Kannur" },
                        { label: "Kasargod", value: "Kasargod" },
                      ]}
                    />
                  </Col>

                  <Col md="2 mb-2">
                    <Select
                      isClearable
                      name="student"
                      placeholder="Revenue Division"
                      options={[
                        { label: "Kannur RD", value: "Kannur RD" },
                        { label: "Taliparamba RD", value: "Taliparamba RD" },
                      ]}
                    />
                  </Col>
                  <Col md="2 mb-2">
                    <Select isClearable name="student" placeholder="Taluks" />
                  </Col>
                  <Col md="2 mb-2">
                    <Select
                      isClearable
                      name="student"
                      placeholder="Revenue Villages"
                    />
                  </Col>
                  <Col md={4}>
                    <Row>
                      <Col md={5}>
                        <AvField name="startDate" type="date" />
                      </Col>
                      <Col md={5}>
                        <AvField name="endDate" type="date"></AvField>
                      </Col>
                      <Col md="2">
                        <div>
                          <button
                            title="reset"
                            style={{
                              border: "none",
                              color: "green",
                              fontSize: "20px",
                              background: "unset",
                            }}
                            className="fas fa-undo mt-1"
                          ></button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </AvForm>
          </Row>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={12}>
                      <MiniWidget reports={reportsTwo} />
                    </Col>

                    <Col md={12}>
                      <MiniWidget reports={reportsThree} />
                    </Col>
                  </Row>
                </Col>

                <Col md={6}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={6}>
                          <PieChart />
                        </Col>
                        <Col md={6}>
                          <PieChartTwo />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col md={6}>
              <MiniWidget reports={reportsOne} />
            </Col>

            <Col md={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-1">
                    Taluk Wise Plan Progressions
                  </h4>
                  <BarChart />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            {/* <Col xl={8}>
              <SalesAnalyticsChart />
            </Col> */}
            {/* <Col xl={4}>
              <Card className="bg-primary">
                <CardBody>
                  <Row className="align-items-center">
                    <Col sm={8}>
                      <p className="text-white font-size-18">
                        Enhance your <b>Campaign</b> for better outreach{" "}
                        <i className="mdi mdi-arrow-right"></i>
                      </p>
                      <div className="mt-4">
                        <Link
                          to="#"
                          className="btn btn-success waves-effect waves-light"
                        >
                          Upgrade Account!
                        </Link>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="mt-4 mt-sm-0">
                        <img
                          src={setupanalytics}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <TopProduct />
            </Col> */}
            {/* </Row>
          <Row> */}
            {/* <Col xl={4}>
              <TopUser />
            </Col> */}
            {/* <Col xl={4}>
              <RecentActivity />
            </Col> */}
            {/* <Col xl={4}>
              <SocialSource />
            </Col> */}
          </Row>
          {/* <LatestTransaction /> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
