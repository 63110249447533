import moment from "moment"

import { APP_NAME, CRYPTO_HASH } from "../config"

import CryptoJS from "crypto-js"

export const setBrowserToken = token => {
  localStorage.setItem("browserToken", token)
}

export const getFirstday = date =>
  moment().startOf("month").format("YYYY-MM-DD")

export const getDate = () => moment().format("YYYY-MM-DD")

export function encrypt(data) {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    CRYPTO_HASH,
  ).toString()
  return encrypted
}

export const decrypt = data => {
  const decrypted = CryptoJS.AES.decrypt(data, CRYPTO_HASH).toString(
    CryptoJS.enc.Utf8,
  )
  return decrypted
}

export const setTitle = (title = "") => {
  document.title = `${title} | ${APP_NAME}`
  return title
}

export const generateShortCode = value => {
  const words = value.split(" ")
  let shortCode = ""

  const excludeWords = new Set(["of", "and"])

  for (const word of words) {
    if (!excludeWords.has(word.toLowerCase())) {
      const cleanWord = word.replace(/[^a-zA-Z0-9]/g, "")
      shortCode += cleanWord.charAt(0).toUpperCase()
    }
  }

  if (value.length > 5 && words.length === 1) {
    shortCode += value.toUpperCase().charAt(value.length - 1)
  }

  return shortCode
}

export const dateConverter = date => {
  const formats = [moment.ISO_8601, "YYYY-MM-DD HH:mm:ss"]
  const isValidDate = moment(date, formats, true).isValid()
  return isValidDate ? moment(date).format("DD-MM-YYYY") : ""
}

export const timeConverter = time => {
  const formats = ["HH:mm:ss", "HH:mm", "YYYY-MM-DD HH:mm:ss"]
  const isValidTime = moment(time, formats, true).isValid()
  return isValidTime ? moment(time, formats).format("hh:mm a") : ""
}

export const toTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  })
}
