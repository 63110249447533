import React from "react"

import ReactApexChart from "react-apexcharts"

const chartapex = () => {
  const series = [
    { name: "Waste Collected", data: [26, 24, 32, 36, 33, 31, 33] },
    { name: "Visit", data: [14, 11, 18, 12, 17, 18, 12] },
    { name: "House Closed", data: [34, 29, 25, 21, 24, 22, 36] },
    { name: "No Waste", data: [13, 19, 15, 19, 13, 11, 18] },
    { name: "Payment Made", data: [17, 29, 16, 18, 13, 16, 11] },
  ]
  const options = {
    chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
    colors: ["#f46a6a", "#5b73e8", "#f1b44c", "#34c38f"],
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      width: [3, 3],
      curve: "straight",
    },
    title: {
      text: "",
      align: "left",
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 6,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      title: {
        text: "Month",
      },
    },
    yaxis: {
      title: {
        text: "Number",
      },
      min: 5,
      max: 40,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="380"
      className="apex-charts"
    />
  )
}

export default chartapex
