import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Button, Label, Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { generateShortCode, setTitle, toTop } from "../../../helpers/functions"

import { MDBDataTable } from "mdbreact"
import "./style.scss"
import { useSelector } from "react-redux"
import { del, get, post, put } from "../../../helpers/api_helper"
import { isArray } from "lodash"
import ReactSelect from "../../../components/Common/ReactSelect"
import toastr from "toastr"
import moment from "moment"

import Swal from "sweetalert2"
import Icon from "../../../components/Common/Icons"

const masterIntitial = {
  name: "",
  type: "",
  district: "",
  shortcode: "",
  footer: "",
}

const Localbody = () => {
  const formRef = useRef()

  const [localbodyTypeOptions, setLocalbodyTypeOptions] = useState([])

  const [master, setMaster] = useState(masterIntitial)
  const [selected, setSelected] = useState({})

  const [selectError, setSelectError] = useState({
    district: false,
    type: false,
  })

  const { distrcts: DISTRICT_OPTION } = useSelector(state => state.Options)

  useEffect(() => {
    fetchTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchLocalBodyTypeOption()
  }, [])

  const [rows, setRows] = useState([])

  const fetchLocalBodyTypeOption = async () => {
    try {
      const response = await get(`/options/localbody/type`)
      setLocalbodyTypeOptions(response.data || [])
    } catch (error) {
      console.log(error)
    }
  }

  const handleSelect = (e, name) => {
    const value = e ? (isArray(e) ? e.map(item => item.value) : e.value) : null
    setSelected(pre => ({ ...pre, [name]: e }))
    setMaster(pre => ({ ...pre, [name]: value }))
    setSelectError(pre => ({ ...pre, [name]: false }))
  }

  const handleChange = e => {
    let { name, value } = e.target
    const data = { [name]: value }
    if (name === "name") data.shortcode = generateShortCode(value)
    setMaster(pre => ({ ...pre, ...data }))
  }

  // console.log("selectError :>> ", selectError)
  // console.log("master :>> ", master)
  // console.log("selected :>> ", selected)

  const handleSubmit = async () => {
    //? <<============== Select Validation ==============>>
    const isError = Object.values(selectError).some(value => value)

    if (isError) {
      return
    }

    try {
      if (master.id) {
        const response = await put("localbody", master)
        toastr.success(response.message)
        reset()
        fetchTableData()
      } else {
        const response = await post("localbody", master)
        toastr.success(response.message)
        reset()
        fetchTableData()
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toastr.error(error?.response?.data?.message)
      } else {
        console.log(error.message)
      }
    }
  }

  const handleSelectValidation = () => {
    const data = {}
    if (!master.district) data.district = true
    if (!master.type) data.type = true
    setSelectError(pre => ({ ...pre, ...data }))
  }

  const handleUpdate = async data => {
    const district = {
      label: data?.district?.name,
      value: data?.district?._id,
    }
    const type = {
      label: data?.type?.name,
      value: data?.type?._id,
    }
    setSelected({ district, type })
    setMaster({
      name: data?.name,
      type: type.value,
      district: district.value,
      shortcode: data?.shortcode,
      footer: data?.footer,
      id: data?._id,
    })
    toTop()
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`localbody/${id}`)
          .then(res => {
            toastr.success(res.message)
            fetchTableData()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  const reset = () => {
    formRef.current.reset()
    setMaster(masterIntitial)
    setSelectError({})
    setSelected({})
  }

  const fetchTableData = async () => {
    const response = await get(`localbody`)

    const data = response?.data || []

    data.map((item, index) => {
      item.id = index + 1

      item.showDate = moment(item.date).format("DD-MM-YYYY")
      item.showTime = moment(item.time, "HH:mm:ss").format("HH:mm a")

      item.districtName = item?.district?.name
      item.localbodyType = item?.type?.name
      item.addedBy = item?.added_by?.firstName

      item.action = (
        <>
          <Icon
            icon={"edit"}
            title={"Edit"}
            onClick={() => {
              handleUpdate(item)
            }}
          />

          <Icon
            icon={"delete"}
            title={"Delete"}
            onClick={() => {
              handleDelete(item._id)
            }}
          />
        </>
      )

      return item
    })
    setRows(data)
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
      },
      {
        label: "Date ",
        field: "showDate",
      },
      {
        label: "Time ",
        field: "showTime",
      },
      {
        label: "District ",
        field: "districtName",
      },

      {
        label: " Type",
        field: "localbodyType",
      },
      {
        label: " Name",
        field: "name",
      },
      {
        label: "Shortcode",
        field: "shortcode",
      },
      {
        label: "Reciept Footer",
        field: "footer",
      },
      {
        label: "Staff",
        field: "addedBy",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem={setTitle("Localbody")} />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleSubmit(e, v)
                    }}
                    onSubmit={handleSelectValidation}
                  >
                    <Row>
                      <Col md="3">
                        <ReactSelect
                          name="district"
                          label="District"
                          onChange={handleSelect}
                          value={selected.district}
                          options={DISTRICT_OPTION}
                          isInvalid={selectError.district}
                        />
                      </Col>

                      <Col md="3">
                        <ReactSelect
                          name="type"
                          label="Type"
                          onChange={handleSelect}
                          value={selected.type}
                          options={localbodyTypeOptions}
                          isInvalid={selectError.type}
                        />
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Name</Label>
                          <AvField
                            name="name"
                            placeholder="Localbody Name"
                            type="text"
                            errorMessage="Enter localbody Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={handleChange}
                            value={master.name || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Short Code</Label>
                          <AvField
                            name="shortcode"
                            placeholder="Short Code"
                            type="text"
                            errorMessage="Enter localbody Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChange}
                            value={master.shortcode || ""}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Receipt Footer content
                          </Label>
                          <AvField
                            name="footer"
                            placeholder="Receipt Footer content"
                            type="text"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            onChange={handleChange}
                            value={master.footer || ""}
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mt-4 pt-1">
                          <Button
                            color={master.id ? "warning" : "primary"}
                            type="submit"
                            className="me-2"
                          >
                            {master.id ? "Update" : "Submit"}
                          </Button>

                          <Button
                            onClick={() => reset()}
                            type="reset"
                            color="danger"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="localbodyTableId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Localbody
